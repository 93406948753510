import { graphql } from 'gatsby';
export default from './instrumente';


export const instrumenteQuery = graphql `
  query instrumentENQuery {
    pickerTexte: contentfulInstrumenteContent(node_locale: {eq: "en"}) {
      ...InstrumenteQueryFragment
    }
  }
`
